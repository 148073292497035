
import React from 'react'

import { Switch, Route } from 'react-router-dom'
import { Error404, DevicePreview } from '@touchlay/frontend-base'

const Presentation = (props) => {
  const license = window.__tl_license

  if (!license) {
    return <Error404 />
  }

  return (<DevicePreview
    {...props}
    configOverride={{ 'router.type': 'browser', 'router.prefixPath': '' }}
    licenseOverride={license}
  />)
}

const routes = (
  <Switch>
    <Route component={Presentation} path='/' />
    <Route component={Error404} />
  </Switch>
)

export default routes
